import { mockInstance } from '../envClient';
import { Cart } from '../../context/types';

const baseUrl = 'https://dev-platform.konfio.mx/core/';

const cartIdMock =
  '2-7d8a8694175f25a32e9c21090e804fc6c6fd653d20da1d85f678617c1e9415803b551747d7ce440dfc48990eac4e5ef6';

const cartMock: Cart = {
  items: [],
  cartId: cartIdMock
};

const cartItemsMock = {
  '1421919000018128092': {
    variantId: '1421919000018128092',
    sku: 'S80',
    name: 'Pin Pad mini',
    sellingPrice: 559.0,
    labelPrice: 749.0,
    images: [
      {
        title: 'Pin Pad mini',
        url: 'https://konfio-dev.zohoecommerce.com//product-images/PMINI2022perfilizq.png/4857350000000156643/1400x1400',
        alternateText: 'Pin Pad mini',
        order: 0
      }
    ]
  },
  '1421919000015706064': {
    variantId: '1421919000015706064',
    sku: 'T52',
    name: 'SmartPad',
    sellingPrice: 2199.0,
    labelPrice: 3599.0,
    images: [
      {
        title: 'SMARTPAD',
        url: 'https://konfio-dev.zohoecommerce.com//product-images/SMART_0002.png/4857350000000156671/1400x1400',
        alternateText: 'SMARTPAD',
        order: 0
      }
    ]
  },
  '4857350000000135003': {
    variantId: '4857350000000135003',
    sku: 'T7000',
    name: 'SmartPad 2.0',
    sellingPrice: 2199.0,
    labelPrice: 3599.0,
    images: [
      {
        title: 'SUNMI-P2',
        url: 'https://konfio-dev.zohoecommerce.com//product-images/SunmiSide2.jpg/4857350000000156743/1400x1400',
        alternateText: 'SUNMI-P2',
        order: 0
      }
    ]
  }
};

mockInstance.onPost(`${baseUrl}shop/cart`).reply(config => {
  const items = cartMock.items;

  const entryIndex = items.findIndex(e => e.variantId === config.data.productVariantId);

  if (entryIndex > -1) {
    items[entryIndex].quantity += parseInt(config.data.quantity, 10);
  } else {
    items.push({
      ...cartItemsMock[config.data.productVariantId as keyof typeof cartItemsMock],
      quantity: parseInt(config.data.quantity, 10)
    });
  }

  return [200, { ...cartMock }];
});

mockInstance.onPut(`${baseUrl}shop/cart`).reply(config => {
  const items = cartMock.items;

  const entryIndex = items.findIndex(e => e.variantId === config.data.productVariantId);

  items[entryIndex].quantity = parseInt(config.data.quantity, 10);

  return [200, { ...cartMock }];
});
