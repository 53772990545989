import * as React from 'react';
import { coreGet, corePost, corePut } from '../api/core';
import { Cart } from './types';
import { isValid, isAfter, addMonths, format } from 'date-fns';

type ContextConfig = {
  loading: boolean;
  loadingCart: boolean;
  showCart: boolean;
  isPaymentPage: boolean;
  setIsPaymentPage: React.Dispatch<React.SetStateAction<boolean>>;
  addVariantToCart: (variantId: string, quantity: string) => void;
  updateLineItem: (cartId: string, lineItemID: string, quantity: string) => void;
  removeLineItem: (cartId: string, lineItemId: string) => void;
  cart: Cart;
};

type ProviderProps = {
  children: JSX.Element;
};

type LocalStorageInfo = {
  id: string | null;
  first?: string;
  last?: string;
};

const defaultValues: ContextConfig = {
  loading: false,
  loadingCart: false,
  showCart: false,
  isPaymentPage: false,
  setIsPaymentPage: null,
  addVariantToCart: () => {},
  updateLineItem: () => {},
  removeLineItem: () => {},
  cart: {
    items: []
  }
};

export const StoreContext = React.createContext<ContextConfig>(defaultValues);

const isBrowser = typeof window !== `undefined`;
export const localStorageKey = `zoho_cart_id`;

const getLocalStorageInfo = (): LocalStorageInfo => {
  const localStorageInfo = localStorage.getItem(localStorageKey);
  try {
    return JSON.parse(localStorageInfo || '{}');
  } catch {
    return { id: localStorageInfo || null };
  }
};

const isCartValid = (localStorageInfo: LocalStorageInfo): boolean => {
  if (localStorageInfo.id && localStorageInfo.id !== 'null') {
    const firstDate = new Date(localStorageInfo.first);
    const lastDate = new Date(localStorageInfo.last);
    const now = new Date();

    if (isValid(firstDate) && isValid(lastDate)) {
      return isAfter(addMonths(firstDate, 3), now) && isAfter(addMonths(lastDate, 1), now);
    }
    return false;
  } else {
    return false;
  }
};

export const StoreProvider: React.FC<ProviderProps> = ({ children }) => {
  const [cart, setCart] = React.useState(defaultValues.cart);
  const [loading, setLoading] = React.useState(false);
  const [loadingCart, setLoadingCart] = React.useState(false);
  const [showCart, setShowCart] = React.useState(defaultValues.showCart);
  const [isPaymentPage, setIsPaymentPage] = React.useState(defaultValues.isPaymentPage);

  const setCartItem = (cart: Cart) => {
    if (isBrowser) {
      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          id: cart.cartId,
          first: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
          last: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
        })
      );
    }
    setCart(cart);
  };

  const updateLocalStorageInfo = () => {
    if (isBrowser) {
      const localStorageInfo = localStorage.getItem(localStorageKey);
      const parsedCart: LocalStorageInfo = JSON.parse(localStorageInfo);

      localStorage.setItem(
        localStorageKey,
        JSON.stringify({
          id: cart.cartId,
          first: parsedCart.first,
          last: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx")
        })
      );
    }
  };

  React.useEffect(() => {
    const initializeCart = async () => {
      const existingLocalStorageInfo = isBrowser ? getLocalStorageInfo() : { id: null };
      try {
        if (isCartValid(existingLocalStorageInfo)) {
          const existingCart = await coreGet(`shop/checkout/${existingLocalStorageInfo.id}`);

          if (!existingCart.error) {
            setCartItem(existingCart.data.cart);
            return;
          }
        }
        const newCart = await corePost('shop/cart', {
          productVariantId: '4857350000000135003',
          quantity: '0'
        });

        setCartItem(newCart.data);
      } catch (e) {
        console.error(e);
        localStorage.setItem(localStorageKey, JSON.stringify({ id: null }));
        setLoading(false);
      }
    };
    initializeCart();
  }, []);

  React.useEffect(() => {
    cart.items.length > 0 || isPaymentPage ? setShowCart(true) : setShowCart(false);
  }, [isPaymentPage, cart.items.length]);

  const addVariantToCart = async (variantId: string, quantity: string) => {
    try {
      setLoading(true);
      const cartId = cart.cartId;

      const res = await corePost('shop/cart', {
        cartId: cartId,
        productVariantId: variantId,
        quantity: quantity
      });

      if (res.error) {
        console.error('Shop endpoint error: ', res.error);
      } else {
        setCart(res.data);
        updateLocalStorageInfo();
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const updateLineItem = async (cartId: string, variantId: string, quantity: string) => {
    try {
      setLoadingCart(true);

      const res = await corePut('shop/cart', {
        cartId: cartId,
        productVariantId: variantId,
        quantity: quantity
      });

      if (res.error) {
        console.error('Shop endpoint error: ', res.error);
      } else {
        setCart(res.data);
        updateLocalStorageInfo();
      }

      setLoadingCart(false);
    } catch (err) {
      setLoadingCart(false);
    }
  };

  const removeLineItem = async (cartId: string, variantId: string) => {
    try {
      setLoadingCart(true);
      const res = await corePut('shop/cart', {
        cartId: cartId,
        productVariantId: variantId,
        quantity: '0'
      });

      if (res.error) {
        console.error('Shop endpoint error: ', res.error);
      } else {
        setCart(res.data);
        updateLocalStorageInfo();
      }

      setLoadingCart(false);
    } catch (err) {
      setLoadingCart(false);
    }
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        updateLineItem,
        removeLineItem,
        cart,
        loading,
        loadingCart,
        showCart,
        isPaymentPage,
        setIsPaymentPage
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
